/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import toast, { Toaster } from 'react-hot-toast';
import loading from '../assets/loading.gif';
import '../styles/download-btn.scss';
import { AuthTypes } from '../models/enums';
import { getEntitlementTokens, getPackage } from '../services/package-api';
import * as downloadUtils from '../utils/download';
import { showError } from '../utils/error';
export function PackageDownloadButton(props) {
    const LOADING_SPINNER = React.createElement("img", { className: "img-center", src: loading, height: 60 });
    const TOKEN_PAGE_SIZE = 50;
    const [tokenHtml, setTokenHtml] = useState(React.createElement("img", { className: "img-center", src: loading, height: 60 }));
    const [entitlementButtonHtml, setEntitlementButtonHtml] = useState();
    const [tooltipHtml, setTooltipHtml] = useState('');
    const { repo, slugPerm, isDownloadSignature, isDownloadable, downloadException, isPrivate, isFileDownload, fileSlugPerm, csrfToken, apiUrl, } = props;
    /**
     * Called on load to decide whether to show the dropdown, and set the tooltip text
     */
    useEffect(() => {
        isPrivate ? setEntitlementButtonHtml(React.createElement("button", { className: "btn btn-success btn-xs dropdown-toggle fix-right-rounding", type: "button", "data-testid": "entitlementDropdownBtn", onClick: () => {
                var _a;
                const menuIsOpen = (_a = document.getElementById('wrapper')) === null || _a === void 0 ? void 0 : _a.classList.contains('open');
                if (!menuIsOpen) {
                    requestEntitlementTokens();
                }
            }, "data-toggle": "dropdown", "data-also-toggle": "tooltip", "aria-haspopup": "true", "data-container": "body", "data-placement": "top", disabled: !isDownloadable },
            React.createElement("i", { className: "fas fa-fw fa-caret-down" }))) : setEntitlementButtonHtml(null);
        isDownloadable ? setTooltipHtml('Download using default token') : setTooltipHtml(downloadException || 'No files to download');
    }, []);
    /**
     * Requests the artifact data, then uses the cdn or signature url to download it
     */
    function downloadPackage(auth, token) {
        getPackage(apiUrl, csrfToken, repo, slugPerm, auth, token).then((data) => {
            const downloadData = getDownloadData(data);
            downloadUtils.downloadFromURL(downloadData.url, downloadData.filename);
        }).catch((err) => {
            const message = JSON.parse(err.message);
            showError('Error: Unable to get package.', message.status || null);
        });
    }
    /**
     * Copy download link to clipboard and display appropriate message
     */
    function copyDownloadLink(auth, token) {
        getPackage(apiUrl, csrfToken, repo, slugPerm, auth, token).then((data) => {
            navigator.clipboard.writeText(getDownloadData(data).url);
            toast.success('Link copied to clipboard');
        }).catch((err) => {
            const message = JSON.parse(err.message);
            showError('Error: Unable to get download link.', message.status || null);
        });
    }
    /**
     * Return the download url and filename for the correct file
     */
    function getDownloadData(packageInfo) {
        if (!isFileDownload) {
            return isDownloadSignature ? { url: packageInfo.signature_url, filename: packageInfo.filename } : { url: packageInfo.cdn_url, filename: packageInfo.filename };
        }
        const file = packageInfo.files.filter((f) => f.slug_perm === fileSlugPerm);
        if (file.length > 0) {
            return { url: file[0].cdn_url, filename: file[0].filename };
        }
        throw new Error(JSON.stringify({ message: `No matching file found to download slug_perm ${fileSlugPerm}` }));
    }
    /**
     * Retrieves the package info and uses it to construct the dropdown list
     */
    function requestEntitlementTokens() {
        Promise.all([
            getEntitlementTokens(apiUrl, csrfToken, repo, TOKEN_PAGE_SIZE.toString(), 'token_type:user'),
            getEntitlementTokens(apiUrl, csrfToken, repo, TOKEN_PAGE_SIZE.toString(), 'token_type:standard'),
        ]).then((data) => {
            const [userToken, standardTokens] = data;
            if (userToken.length === 0 && standardTokens.length === 0) {
                setTokenHtml(React.createElement("li", { className: "info-text" }, "No tokens available"));
            }
            else {
                setTokenHtml(React.createElement("span", { "data-testid": "token-dropdown" },
                    constructUserTokenHtml(userToken),
                    React.createElement("li", { className: "dropdown-header" }, "Use Entitlement Token:"),
                    standardTokens.map((token, index) => (React.createElement("li", { key: index, className: "dropdown-item" },
                        React.createElement("a", { role: "button", tabIndex: 0, "data-testid": "token-link", onClick: () => downloadPackage(AuthTypes.TOKEN, token.token) }, token.name),
                        React.createElement("i", { className: "far fa-fw fa-copy", role: "button", tabIndex: 0, onClick: () => copyDownloadLink(AuthTypes.TOKEN, token.token) })))),
                    getMaxLoadedText(standardTokens),
                    React.createElement("li", { role: "separator", className: "divider" }),
                    React.createElement("li", { className: "dropdown-header" }, "Use Other Credentials:"),
                    React.createElement("li", { className: "dropdown-item" },
                        React.createElement("a", { role: "button", tabIndex: 0, "data-testid": "token-link", onClick: () => downloadPackage(AuthTypes.BASIC) }, "Basic (Email/Password)"),
                        React.createElement("i", { className: "far fa-fw fa-copy", role: "button", tabIndex: 0, onClick: () => copyDownloadLink(AuthTypes.BASIC) }))));
            }
        }).catch((err) => {
            setTokenHtml(React.createElement("li", { className: "info-text" }, "No tokens available"));
            const message = JSON.parse(err.message);
            showError('Unable to get entitlement tokens.', message.status || null);
        });
    }
    /**
     * Ensure that a userToken is present, and constructs the appropriate list item to use in the dropdown
     * @param userToken : EntitlementToken
     * @param downloadUrl : string
     * @returns HTMLElement
     */
    function constructUserTokenHtml(userToken) {
        return userToken && userToken.length !== 0 ? (React.createElement("span", null,
            React.createElement("li", { className: "dropdown-header test" }, "Use User-specific Token:"),
            React.createElement("li", { className: "dropdown-item" },
                React.createElement("a", { role: "button", tabIndex: 0, "data-testid": "user-token-link", onClick: () => downloadPackage(AuthTypes.TOKEN, userToken[0].token) }, userToken[0].name),
                React.createElement("i", { className: "far fa-fw fa-copy", "data-testid": "user-token-copy", role: "button", tabIndex: 0, onClick: () => copyDownloadLink(AuthTypes.TOKEN, userToken[0].token) })),
            React.createElement("li", { role: "separator", className: "divider" }))) : null;
    }
    /**
     * Show help text if the current max number of tokens have been retrieved
     * TODO: add infinite scrolling to keep loading tokens
     * @param tokens : EntitlementToken[]
     * @returns
     */
    function getMaxLoadedText(tokens) {
        return tokens.length === TOKEN_PAGE_SIZE ? React.createElement("li", { className: "info-text" }, "If you can't see the token you're looking for, try searching, or further restricting your search") : null;
    }
    /**
     * Called each time the input value changes on the search
     * Debounces the service call by 1 second, after this time either search for the token, or resset the tokens
     * @param term: string
     */
    let timer;
    function handleSearchChange(term) {
        clearTimeout(timer);
        timer = setTimeout(() => (term !== '' ? searchForToken(term) : requestEntitlementTokens()), 1000);
    }
    /**
     * Call the entitlemnts service with a query param
     * @param term : string
     */
    function searchForToken(term) {
        // Ideally this would be in the function above, before the timer is set, but doing so cancels the debounce
        setTokenHtml(LOADING_SPINNER);
        getEntitlementTokens(apiUrl, csrfToken, repo, TOKEN_PAGE_SIZE.toString(), term).then((tokens) => {
            setTokenHtml(React.createElement("span", { "data-testid": "token-dropdown" },
                React.createElement("li", { className: "dropdown-header" }, "Search Results:"),
                constructSearchResults(tokens),
                getMaxLoadedText(tokens)));
        });
    }
    /**
     * Build the list of tokens, or disply info text to the user
     * @param tokens : EntitlementToken[]
     * @returns
     */
    function constructSearchResults(tokens) {
        if (tokens.length !== 0) {
            return (tokens.map((token, index) => (React.createElement("li", { className: "dropdown-item", key: index },
                React.createElement("a", { role: "button", tabIndex: 0, "data-testid": "token-link", onClick: () => downloadPackage(AuthTypes.TOKEN, token.token) }, token.name),
                React.createElement("i", { className: "far fa-fw fa-copy", role: "button", tabIndex: 0, onClick: () => copyDownloadLink(AuthTypes.TOKEN, token.token) })))));
        }
        return React.createElement("li", { className: "info-text" }, "No matches found");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrapper", className: "btn-group hide-external", style: { display: 'inline-block' } },
            entitlementButtonHtml,
            React.createElement("ul", { className: "dropdown-menu dropdown-menu-right scrollable-menu", role: "menu" },
                React.createElement("span", { className: "input-group" },
                    React.createElement("span", { className: "input-group-btn" },
                        React.createElement("button", { type: "button", className: "btn btn-primary btn-xs search-icon", "aria-label": "Search" },
                            React.createElement("i", { className: "fas fa-search" }))),
                    React.createElement("input", { className: "search-input", type: "text", placeholder: "Search by name...", onChange: (e) => {
                            var _a;
                            if (((_a = e.target.value) === null || _a === void 0 ? void 0 : _a.length) !== 1) {
                                handleSearchChange(e.target.value);
                            }
                        }, "data-testid": "entitlementSearchInput" })),
                React.createElement("li", { role: "separator", className: "divider" }),
                tokenHtml),
            React.createElement("button", { className: "btn btn-success btn-xs", id: "defaultDownloadBtn", onClick: () => downloadPackage(AuthTypes.DEFAULT), disabled: !isDownloadable, "data-testid": "defaultDownloadBtn", type: "button" },
                React.createElement("span", { "data-tip": true, "data-for": `downloadTooltip-${slugPerm}`, id: "downloadBtnTooltipContainer" },
                    React.createElement("i", { className: "fas fa-fw fa-cloud-download-alt" })))),
        React.createElement(ReactTooltip, { id: `downloadTooltip-${slugPerm}`, place: "bottom" }, tooltipHtml),
        React.createElement(Toaster, null)));
}
