import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { PackageDownloadButton } from './download-button';
import { LicensePolicyManagementList } from './policy-management/licenses/list';
import { OpenIDConnectProviderManagementList } from './openid-connect/providers/list';
import { VulnerabilityPolicyManagementList } from './policy-management/vulnerabilities/list';
import { DenyPolicyManagementList } from './policy-management/deny/list';
import { GlobalStateContextProvider } from './globalContext';
import { UsageDashboard } from './dashboard/usage-dashboard';
import { LeftMenu } from './left-menu/left-menu';
const containers = document.querySelectorAll('#react-component-container');
const scriptTag = containers[0].querySelector('#react-data');
const dataJson = JSON.parse((scriptTag === null || scriptTag === void 0 ? void 0 : scriptTag.textContent) || '');
Sentry.init({
    dsn: 'https://85cf84319e204994878d7afb03753450@o89590.ingest.sentry.io/195170',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    environment: dataJson.env,
    release: typeof SHA1 !== 'undefined' ? SHA1 : 'development',
});
containers.forEach((element) => {
    const container = element;
    if (container.dataset.component === 'LeftMenu') {
        const dataEle = container.querySelector('#react-data');
        const leftMenuData = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        leftMenuData.can_view_org_membership = leftMenuData.can_view_org_membership === 'True';
        leftMenuData.can_view_org_audit_log = leftMenuData.can_view_org_audit_log === 'True';
        leftMenuData.can_view_org_settings = leftMenuData.can_view_org_settings === 'True';
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(GlobalStateContextProvider, { value: leftMenuData },
            React.createElement(LeftMenu, { canViewOrgMembership: leftMenuData.can_view_org_membership, canViewOrgAuditLog: leftMenuData.can_view_org_audit_log, canViewOrgSettings: leftMenuData.can_view_org_settings, selected: leftMenuData.selected })));
    }
    else if (container.dataset.component === 'PackageDownloadButton') {
        const dataEle = container.querySelector('#react-data');
        const downloadDataJson = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        downloadDataJson.download_signature = downloadDataJson.download_signature === 'True';
        downloadDataJson.is_downloadable = !(downloadDataJson.is_downloadable === 'False' || downloadDataJson.is_downloadable === 'None');
        downloadDataJson.is_private = downloadDataJson.is_private === 'True';
        downloadDataJson.is_file_download = downloadDataJson.is_file_download === 'True';
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(PackageDownloadButton, { repo: downloadDataJson.repo, slugPerm: downloadDataJson.slug_perm, isDownloadSignature: downloadDataJson.download_signature, isDownloadable: downloadDataJson.is_downloadable, downloadException: downloadDataJson.download_exception, isPrivate: downloadDataJson.is_private, isFileDownload: downloadDataJson.is_file_download, fileSlugPerm: downloadDataJson.file_slug_perm, csrfToken: downloadDataJson.csrf_token, apiUrl: downloadDataJson.api_url }));
    }
    else if (container.dataset.component === 'LicensePolicyManagement') {
        const dataEle = container.querySelector('#react-data');
        const policyDataJson = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(GlobalStateContextProvider, { value: policyDataJson },
            React.createElement(LicensePolicyManagementList, null)));
    }
    else if (container.dataset.component === 'DenyPolicyManagement') {
        const dataEle = container.querySelector('#react-data');
        const policyDataJson = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(GlobalStateContextProvider, { value: policyDataJson },
            React.createElement(DenyPolicyManagementList, null)));
    }
    else if (container.dataset.component === 'OpenIDConnectProviderManagement') {
        const dataEle = container.querySelector('#react-data');
        const providerData = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(GlobalStateContextProvider, { value: providerData },
            React.createElement(OpenIDConnectProviderManagementList, null)));
    }
    else if (container.dataset.component === 'VulnerabilityPolicyManagement') {
        const dataEle = container.querySelector('#react-data');
        const policyDataJson = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(GlobalStateContextProvider, { value: policyDataJson },
            React.createElement(VulnerabilityPolicyManagementList, null)));
    }
    else if (container.dataset.component === 'Dashboard') {
        const dataEle = container.querySelector('#react-data');
        const dashboardDataJson = JSON.parse((dataEle === null || dataEle === void 0 ? void 0 : dataEle.textContent) || '');
        const root = ReactDOMClient.createRoot(container);
        root.render(React.createElement(GlobalStateContextProvider, { value: dashboardDataJson },
            React.createElement(UsageDashboard, null)));
    }
});
