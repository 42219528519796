export class DenyPackagePolicy {
    constructor(created_at = new Date(), description = '', enabled = true, name = '', slug_perm, updated_at = new Date(), package_query_string = null, revision_count = 1) {
        this.created_at = new Date();
        this.description = '';
        this.enabled = true;
        this.name = '';
        this.slug_perm = '';
        this.updated_at = new Date();
        this.package_query_string = null;
        this.revision_count = 1;
        if (slug_perm) {
            this.slug_perm = slug_perm;
        }
        this.created_at = created_at;
        this.description = description;
        this.enabled = enabled;
        this.name = name;
        this.updated_at = updated_at;
        this.package_query_string = package_query_string;
        this.revision_count = revision_count;
    }
}
