import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
export function showError(msg, status) {
    toast.error(`${msg}\nStatus: ${status}`, {
        duration: 4000,
        position: 'bottom-right',
        style: { color: '#FFF', backgroundColor: '#b03030' },
        iconTheme: {
            primary: '#b03030',
            secondary: '#fff',
        },
    });
}
export function handleErrors(response) {
    if (!response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then((json) => {
                var _a;
                // refresh the page if the token has expired
                if (json && ((_a = json.detail) === null || _a === void 0 ? void 0 : _a.includes('Token has expired'))) {
                    showError('Your token has expired, refreshing page', '403');
                    setTimeout(() => window.location.reload(), 3000);
                }
                else {
                    // eslint-disable-next-line no-param-reassign, @typescript-eslint/dot-notation
                    json['status'] = response.status;
                    throw Error(JSON.stringify(json));
                }
            });
        }
        return response.text().then((data) => {
            if (!response.ok) {
                Sentry.captureException(Error(`${response.status} ${response.url}`));
                throw Error(data);
            }
        });
    }
    // 200's will return here
    return response;
}
