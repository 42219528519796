export var AuthTypes;
(function (AuthTypes) {
    AuthTypes["BASIC"] = "basic";
    AuthTypes["DEFAULT"] = "default";
    AuthTypes["TOKEN"] = "token";
})(AuthTypes || (AuthTypes = {}));
export var OrganizationTeamUserRole;
(function (OrganizationTeamUserRole) {
    OrganizationTeamUserRole["MANAGER"] = "manager";
    OrganizationTeamUserRole["MEMBER"] = "member";
})(OrganizationTeamUserRole || (OrganizationTeamUserRole = {}));
export var OrganizationUserRole;
(function (OrganizationUserRole) {
    OrganizationUserRole["COLLABORATOR"] = "collaborator";
    OrganizationUserRole["MEMBER"] = "member";
    OrganizationUserRole["OWNER"] = "owner";
})(OrganizationUserRole || (OrganizationUserRole = {}));
export var PolicyViolationActionType;
(function (PolicyViolationActionType) {
    PolicyViolationActionType["QUARANTINE"] = "quarantine";
})(PolicyViolationActionType || (PolicyViolationActionType = {}));
export var PolicyRuleType;
(function (PolicyRuleType) {
    PolicyRuleType["DENY"] = "DenyLicensePolicyRule";
})(PolicyRuleType || (PolicyRuleType = {}));
export var PolicyEvaluationStatus;
(function (PolicyEvaluationStatus) {
    PolicyEvaluationStatus["PENDING"] = "Pending";
    PolicyEvaluationStatus["IN_PROGRESS"] = "In Progress";
    PolicyEvaluationStatus["COMPLETE"] = "Complete";
    PolicyEvaluationStatus["CANCELLED"] = "Cancelled";
    PolicyEvaluationStatus["ERRORED"] = "Errored";
})(PolicyEvaluationStatus || (PolicyEvaluationStatus = {}));
