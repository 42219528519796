/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table, } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import loading from '../../../assets/loading.gif';
import { LicensePolicy } from '../../../models/license-policy';
import { createLicensePolicy, editLicensePolicy, getLicenses } from '../../../services/policy-api';
import { showError } from '../../../utils/error';
import GlobalStateContext from '../../globalContext';
export function LicensePolicyManagementForm(props) {
    const { activePolicy, closeModal, createMode, isOpen, } = props;
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const LOADING_SPINNER = React.createElement("img", { className: "img-center", src: loading, height: 60 });
    const [policy, setPolicy] = useState(activePolicy !== null && activePolicy !== void 0 ? activePolicy : new LicensePolicy());
    const [errors, setErrors] = useState();
    const [tableRows, setTableRows] = useState();
    const [licensesForTable, setLicensesForTable] = useState([]);
    const [fullLicenseList, setFullLicenseList] = useState([]);
    useEffect(() => {
        getLicenses(apiUrl, csrfToken).then((data) => {
            setFullLicenseList(data);
            setLicensesForTable(data);
        });
    }, []);
    const onPolicyUpdate = (attribute, value) => {
        const current = Object.assign({}, policy);
        if (attribute === 'package_query_string' && typeof value === 'string' && value.trim().length === 0) {
            current[attribute] = null;
        }
        else {
            current[attribute] = value;
        }
        setPolicy(current);
    };
    const constructRow = (id, name, checked) => (React.createElement("tr", { className: "license-table-row", key: id },
        React.createElement("td", { width: 24 },
            React.createElement(Form.Check, { type: "checkbox", id: `checkbox-${id}`, onChange: (e) => onSelectionChange(id, e.target.checked), checked: checked, "data-testid": `select-row-${id}` })),
        React.createElement("td", null, name)));
    const onSelectionChange = (spdx_id, isSelected) => {
        if (isSelected) {
            onPolicyUpdate('spdx_identifiers', policy.spdx_identifiers.concat([spdx_id]));
        }
        else {
            onPolicyUpdate('spdx_identifiers', policy.spdx_identifiers.filter((id) => id !== spdx_id));
        }
    };
    useEffect(() => {
        setTableRows(licensesForTable.map((license) => (constructRow(license.spdx_identifier, license.full_name, policy.spdx_identifiers.includes(license.spdx_identifier)))));
    }, [licensesForTable, policy]);
    const createOrUpdate = () => (createMode ? createLicensePolicy(apiUrl, csrfToken, orgName, policy) : editLicensePolicy(apiUrl, csrfToken, orgName, policy));
    const onSubmit = () => {
        createOrUpdate().then(() => {
            closeModal();
        }).catch((err) => {
            const message = JSON.parse(err.message);
            if (message.status && message.status === 422) {
                setErrors(message);
            }
            else {
                showError(message.detail, message.status);
            }
        });
    };
    const isErrorPresent = (fieldName) => (errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) && errors.fields[fieldName].length > 0;
    const getErrorText = (fieldName) => ((errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) ? errors === null || errors === void 0 ? void 0 : errors.fields[fieldName].join(', ') : '');
    const selectAll = (checked) => {
        onPolicyUpdate('spdx_identifiers', checked ? licensesForTable.map((license) => license.spdx_identifier) : []);
    };
    const filterRows = (value) => {
        setLicensesForTable(fullLicenseList.filter((l) => l.full_name.toLowerCase().indexOf(value) > -1));
    };
    const loadingRow = (React.createElement("tr", { className: "license-table-row", key: "loading" },
        React.createElement("td", { colSpan: 2 }, LOADING_SPINNER)));
    return (React.createElement(Modal, { id: "license-policy-modal", className: "policy-modal bootstrap-dialog type-success", show: isOpen, onHide: closeModal, backdrop: "static", contentClassName: "panel panel-success" },
        React.createElement(Modal.Header, { className: "panel-heading" },
            React.createElement(Modal.Title, { className: "bootstrap-dialog-title" }, createMode ? 'Create License Policy' : 'Edit License Policy')),
        React.createElement(Toaster, null),
        React.createElement(Modal.Body, { className: "panel-body" },
            React.createElement("p", null, "This policy will take action on all selected licenses listed in the table below. If a package violates the license policy it will be quarantined until it is reviewed."),
            React.createElement(Form, { className: "create-policy-form" },
                React.createElement(Form.Group, { id: "name" },
                    React.createElement(Form.Label, null, "Policy Name"),
                    React.createElement(Form.Control, { name: "name", type: "text", placeholder: "Enter policy name", isInvalid: isErrorPresent('name'), onChange: (event) => { var _a; return onPolicyUpdate('name', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: policy === null || policy === void 0 ? void 0 : policy.name, "data-testid": "name-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('name'))),
                React.createElement(Form.Group, { id: "description" },
                    React.createElement(Form.Label, null, "Description"),
                    React.createElement(Form.Control, { name: "description", type: "text", placeholder: "Enter description", isInvalid: isErrorPresent('description'), onChange: (event) => { var _a; return onPolicyUpdate('description', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: policy === null || policy === void 0 ? void 0 : policy.description, "data-testid": "description-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('description'))),
                React.createElement(Form.Group, { id: "query" },
                    React.createElement(Form.Label, null, "Package Query"),
                    React.createElement(Form.Text, { id: "queryHelp", muted: true },
                        "Use a\u00A0",
                        React.createElement("a", { href: "https://help.cloudsmith.io/docs/search-packages", target: "_blank", rel: "noreferrer" }, "search/filter query"),
                        "\u00A0for precise package policy targeting."),
                    React.createElement(Form.Control, { name: "query", type: "text", placeholder: "format:python AND downloads:>50 AND size:>2048", isInvalid: isErrorPresent('package_query_string'), onChange: (event) => { var _a; return onPolicyUpdate('package_query_string', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: (policy === null || policy === void 0 ? void 0 : policy.package_query_string) || '', "data-testid": "query-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('package_query_string'))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 6, className: "py-4" },
                        React.createElement(Form.Group, null,
                            React.createElement(Form.Label, null, "Allow unknown or absent licenses"),
                            React.createElement(Form.Check, { inline: true, label: "Yes", name: "allow-unknown", type: "radio", id: "unknown-true", onChange: () => onPolicyUpdate('allow_unknown_licenses', true), defaultChecked: policy ? (policy.allow_unknown_licenses === true) : false, "data-testid": "unknown-true-radio" }),
                            React.createElement(Form.Check, { inline: true, label: "No", name: "allow-unknown", type: "radio", id: "unknown-false", onChange: () => onPolicyUpdate('allow_unknown_licenses', false), defaultChecked: policy ? (policy.allow_unknown_licenses === false) : true, "data-testid": "unknown-false-radio" }))),
                    React.createElement(Col, { md: 6, className: "py-4" },
                        React.createElement(Form.Group, null,
                            React.createElement(Form.Label, null, "Quarantine on violation"),
                            React.createElement(Form.Check, { inline: true, label: "Yes", name: "quarantine", type: "radio", id: "quarantine-true", onChange: () => onPolicyUpdate('on_violation_quarantine', true), defaultChecked: policy ? (policy.on_violation_quarantine === true) : false }),
                            React.createElement(Form.Check, { inline: true, label: "No", name: "quarantine", type: "radio", id: "quarantine-false", onChange: () => onPolicyUpdate('on_violation_quarantine', false), defaultChecked: policy ? (policy.on_violation_quarantine === false) : true })))),
                React.createElement(Form.Label, null, "Select licenses to deny"),
                React.createElement("div", { className: "panel-body px-0 pt-0", style: { overflowY: 'scroll', height: 240 } },
                    React.createElement(Table, { hover: true },
                        React.createElement("thead", { className: "table-header small" },
                            React.createElement("tr", null,
                                React.createElement("th", null,
                                    React.createElement(Form.Check, { type: "checkbox", id: "select-all", onChange: (e) => { var _a; return selectAll((_a = e.target) === null || _a === void 0 ? void 0 : _a.checked); }, "data-testid": "select-all" })),
                                React.createElement("th", { className: "valign-container" },
                                    React.createElement("span", { className: "valign-ele" }, "Name"),
                                    React.createElement("div", { className: "pull-right" },
                                        React.createElement(Form.Control, { "data-testid": "table-search", name: "search", type: "text", placeholder: "Search licenses...", onChange: (event) => filterRows(event.target.value.toLowerCase()) }))))),
                        React.createElement("tbody", null, licensesForTable.length === 0 ? loadingRow : tableRows))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: () => closeModal(), "data-testid": "cancel-btn" }, "Cancel"),
            React.createElement(Button, { variant: "success", onClick: () => onSubmit(), "data-testid": "save-btn" }, createMode ? 'Create Policy' : 'Update Policy'))));
}
