/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Form, Modal, } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { createDenyAllowPackagePolicy, editDenyAllowPackagePolicy } from '../../../services/policy-api';
import { showError } from '../../../utils/error';
import GlobalStateContext from '../../globalContext';
import { DenyPackagePolicy } from '../../../models/deny-package-policy';
export function DenyPackagePolicyManagementForm(props) {
    const { activePolicy, closeModal, createMode, isOpen, } = props;
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const [policy, setPolicy] = useState(activePolicy !== null && activePolicy !== void 0 ? activePolicy : new DenyPackagePolicy());
    const [errors, setErrors] = useState();
    const onPolicyUpdate = (attribute, value) => {
        const current = Object.assign({}, policy);
        if (attribute === 'package_query_string' && typeof value === 'string' && value.trim().length === 0) {
            current[attribute] = null;
        }
        else {
            current[attribute] = value;
        }
        setPolicy(current);
    };
    const createOrUpdate = () => (createMode ? createDenyAllowPackagePolicy(apiUrl, csrfToken, orgName, policy) : editDenyAllowPackagePolicy(apiUrl, csrfToken, orgName, policy));
    const onSubmit = () => {
        createOrUpdate().then(() => {
            closeModal();
        }).catch((err) => {
            let message;
            if (err.message && !err.detail && !err.status) {
                message = JSON.parse(err.message);
            }
            else {
                message = err;
            }
            if (message.status && message.status === 422) {
                setErrors(message);
            }
            else {
                showError(message.detail, message.status);
            }
        });
    };
    const isErrorPresent = (fieldName) => (errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) && errors.fields[fieldName].length > 0;
    const getErrorText = (fieldName) => ((errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) ? errors === null || errors === void 0 ? void 0 : errors.fields[fieldName].join(', ') : '');
    if (isErrorPresent('non_field_errors')) {
        setErrors({
            code: 'invalid', detail: 'Invalid input.', fields: { name: ['This field is required.'] },
        });
    }
    return (React.createElement(Modal, { id: "deny-package-policy-modal", className: "policy-modal bootstrap-dialog type-success", show: isOpen, onHide: closeModal, backdrop: "static", contentClassName: "panel panel-success" },
        React.createElement(Modal.Header, { className: "panel-heading" },
            React.createElement(Modal.Title, { className: "bootstrap-dialog-title" }, createMode ? 'Create Package Deny Policy' : 'Edit Package Deny Policy')),
        React.createElement(Toaster, null),
        React.createElement(Modal.Body, { className: "panel-body" },
            React.createElement("p", null, "This policy will prevent the retrieval of packages from Cloudsmith or upstreams based on matching rules."),
            React.createElement(Form, { className: "create-policy-form" },
                React.createElement(Form.Group, { id: "name" },
                    React.createElement(Form.Label, null, "Policy Name"),
                    React.createElement(Form.Control, { name: "name", type: "text", placeholder: "Enter policy name", isInvalid: isErrorPresent('name'), onChange: (event) => { var _a; return onPolicyUpdate('name', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: policy === null || policy === void 0 ? void 0 : policy.name, "data-testid": "name-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('name'))),
                React.createElement(Form.Group, { id: "description" },
                    React.createElement(Form.Label, null, "Description"),
                    React.createElement(Form.Control, { name: "description", type: "text", placeholder: "Enter description", isInvalid: isErrorPresent('description'), onChange: (event) => { var _a; return onPolicyUpdate('description', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: policy === null || policy === void 0 ? void 0 : policy.description, "data-testid": "description-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('description'))),
                React.createElement(Form.Group, { id: "query" },
                    React.createElement(Form.Label, null, "Package Query"),
                    React.createElement(Form.Text, { id: "queryHelp", muted: true },
                        "Use a\u00A0",
                        React.createElement("a", { href: "https://help.cloudsmith.io/docs/search-packages", target: "_blank", rel: "noreferrer" }, "search/filter query"),
                        "\u00A0for precise package policy targeting."),
                    React.createElement(Form.Control, { name: "query", as: "textarea", placeholder: "format:npm AND name:left-pad AND version:>1.1.2", isInvalid: isErrorPresent('package_query_string'), onChange: (event) => { var _a; return onPolicyUpdate('package_query_string', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: (policy === null || policy === void 0 ? void 0 : policy.package_query_string) || '', "data-testid": "query-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('package_query_string'))),
                React.createElement(Form.Group, null,
                    React.createElement(Form.Check, { inline: true, label: "Enabled", name: "enabled", type: "checkbox", id: "enabled-false", onChange: (e) => { var _a; return onPolicyUpdate('enabled', (_a = e.target) === null || _a === void 0 ? void 0 : _a.checked); }, checked: (policy === null || policy === void 0 ? void 0 : policy.enabled) !== undefined ? policy === null || policy === void 0 ? void 0 : policy.enabled : true, "data-testid": "enabled-false-radio" })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: () => closeModal(), "data-testid": "cancel-btn" }, "Cancel"),
            React.createElement(Button, { variant: "success", onClick: () => onSubmit(), "data-testid": "save-btn" }, createMode ? 'Create Policy' : 'Update Policy'))));
}
