/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaOpenid } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ToggleButton from 'react-bootstrap/ToggleButton';
import toast from 'react-hot-toast';
import { BsCheckLg, BsPencilFill, BsTrash, BsXCircle, } from 'react-icons/bs';
import { PanelTable } from '../../lib/panel-table';
import { getProviderSettingsList, deleteProviderSetting, editProviderSetting } from '../../../services/openid-connect';
import { getServiceAccountList } from '../../../services/service-accounts';
import GlobalStateContext from '../../globalContext';
import { ProviderSettingsManagementForm } from './form';
import { ConfirmModal } from '../../lib/confirm-modal';
import '../../../styles/oidc.scss';
export function OpenIDConnectProviderManagementList() {
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const [providerSettings, setProviderSettings] = useState([]);
    const [selectedProviderSetting, setSelectedProviderSetting] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [serviceAccounts, setServiceAccounts] = useState([]);
    const [hasNoServiceAccounts, setHasNoServiceAccounts] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const updateProviderSettings = () => {
        getProviderSettingsList(apiUrl, csrfToken, orgName).then((data) => {
            setProviderSettings(data);
            setLoaded(true);
        }).catch((err) => {
            const message = JSON.parse(err.message);
            toast.error(`Could not get provider settings: ${message.detail}`);
        });
    };
    const toggleProviderSettingEnabled = (providerSetting) => {
        const updatedSetting = providerSetting;
        updatedSetting.enabled = !providerSetting.enabled;
        editProviderSetting(apiUrl, csrfToken, orgName, updatedSetting).then(() => {
            updateProviderSettings();
        }).catch((err) => {
            const message = JSON.parse(err.message);
            toast.error(`Could not update provider setting: ${message.detail}`);
        });
    };
    const getServiceAccounts = () => {
        getServiceAccountList(apiUrl, csrfToken, orgName).then((data) => {
            setServiceAccounts(data);
            setHasNoServiceAccounts(data.length === 0);
            setLoaded(true);
        }).catch((err) => {
            const message = JSON.parse(err.message);
            toast.error(`Could not get list of available service accounts: ${message.detail}`);
        });
    };
    useEffect(() => {
        updateProviderSettings();
        getServiceAccounts();
    }, []);
    const openProviderSettingModal = (providerSetting) => {
        setSelectedProviderSetting(providerSetting);
        setShowModal(true);
    };
    const closeModal = () => {
        setSelectedProviderSetting(null);
        updateProviderSettings();
        setShowModal(false);
        setShowDeleteModal(false);
    };
    const openDeleteModal = (providerSetting) => {
        setSelectedProviderSetting(providerSetting);
        setShowDeleteModal(true);
    };
    const placeholder = {
        text: 'Configure your first OIDC provider using the button above. Enable your OIDC compliant tools to authenticate as a service account using an ephemeral API token.',
        icon: React.createElement(FaOpenid, { size: 120, className: "pull-right" }),
        link: 'https://help.cloudsmith.io/docs/openid-connect',
    };
    const getSettingsToggleButton = (setting) => {
        let icon;
        let text;
        let variant;
        if (setting.enabled === true) {
            icon = React.createElement(BsXCircle, null);
            text = 'Disable';
            variant = 'danger';
        }
        else {
            icon = React.createElement(BsCheckLg, null);
            text = 'Enable';
            variant = 'success';
        }
        const renderTooltip = (props) => (React.createElement(Tooltip, Object.assign({ id: "toggle-button-tooltip" }, props), "Only enabled provider settings will be used to authenticate requests for your organization."));
        return (React.createElement(OverlayTrigger, { placement: "right", delay: { show: 250, hide: 400 }, overlay: renderTooltip },
            React.createElement(ToggleButton, { checked: setting.enabled, className: "toggle-button", "data-testid": `toggle-enabled-button-${setting.slug_perm}`, id: `oidc-provider-setting-toggle-${setting.slug_perm}`, onClick: () => toggleProviderSettingEnabled(setting), type: "checkbox", value: "true", variant: variant },
                icon,
                text)));
    };
    const actionLink = (setting) => (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "link", className: "edit", "data-testid": `edit-button-${setting.slug_perm}`, onClick: () => openProviderSettingModal(setting) },
            React.createElement(BsPencilFill, null)),
        React.createElement(Button, { variant: "link", className: "delete", "data-testid": `delete-button-${setting.slug_perm}`, onClick: () => openDeleteModal(setting) },
            React.createElement(BsTrash, null)),
        getSettingsToggleButton(setting)));
    const enabledContext = useMemo(() => ({ className: 'enabled-icon' }), []);
    const disabledContext = useMemo(() => ({ className: 'disabled-icon' }), []);
    const providerSettingsForTable = providerSettings.map((setting) => {
        let icon;
        if (setting.enabled === true) {
            icon = (React.createElement(IconContext.Provider, { value: enabledContext },
                React.createElement(BsCheckLg, null)));
        }
        else {
            icon = (React.createElement(IconContext.Provider, { value: disabledContext },
                React.createElement(BsXCircle, null)));
        }
        return {
            action: actionLink(setting),
            enabledIcon: icon,
            id: setting.slug_perm,
            name: setting.name,
        };
    });
    const createDisabledWarningMessage = (React.createElement(React.Fragment, null,
        "Your organization does not have any service accounts, to create an OpenID Connect provider you need a service account to map the provider to.",
        ' \nYou can create services ',
        React.createElement(Alert.Link, { href: `https://cloudsmith.io/orgs/${orgName}/accounts/services/` }, "here"),
        ' or ',
        React.createElement(Alert.Link, { href: "https://help.cloudsmith.io/reference/orgs_services_create" }, "via the Cloudsmith API.")));
    return (React.createElement(React.Fragment, null,
        React.createElement(PanelTable, { name: "OIDC Provider Settings", intro: "Configure a new OIDC Provider or edit an existing one. Provider settings defined here are used to validate requests from providers attempting to authenticate as a service account in your organization with an ephemeral token.", data: providerSettingsForTable, loaded: loaded, headings: [{ name: 'enabledIcon', text: 'Enabled?', style: { width: '4rem' } }, { name: 'name', text: 'Name' }, { name: 'action', text: '', style: { width: '20rem' } }], openModal: openProviderSettingModal, placeholderProps: placeholder, createEnabled: loaded && !hasNoServiceAccounts, createDisabledReason: createDisabledWarningMessage }),
        showModal && (React.createElement(ProviderSettingsManagementForm, { isOpen: showModal, activeSettings: selectedProviderSetting, createMode: selectedProviderSetting === null, closeModal: () => closeModal(), serviceAccounts: serviceAccounts })),
        showDeleteModal && selectedProviderSetting
            && (React.createElement(ConfirmModal, { completeMessage: "Provider has been deleted", confirmIcon: React.createElement(BsTrash, null), item: selectedProviderSetting, message: "Are you sure you want to delete this provider?", modalClass: "danger", show: showDeleteModal, onClose: closeModal, confirmFn: deleteProviderSetting }))));
}
