import { PolicyEvaluationStatus } from './enums';
export class LicensePolicy {
    constructor(allow_unknown_licenses = false, description = '', evaluation_status = PolicyEvaluationStatus.COMPLETE, name = '', on_violation_quarantine = true, spdx_identifiers = [], created_at = new Date(), updated_at = new Date(), slug_perm, package_query_string) {
        this.allow_unknown_licenses = false;
        this.created_at = new Date();
        this.description = '';
        this.evaluation_status = PolicyEvaluationStatus.COMPLETE;
        this.name = '';
        this.on_violation_quarantine = true;
        this.slug_perm = '';
        this.spdx_identifiers = [];
        this.updated_at = new Date();
        this.package_query_string = null;
        if (slug_perm) {
            this.slug_perm = slug_perm;
        }
        this.allow_unknown_licenses = allow_unknown_licenses;
        this.description = description;
        this.name = name;
        this.on_violation_quarantine = on_violation_quarantine;
        this.spdx_identifiers = spdx_identifiers;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.package_query_string = package_query_string;
        this.evaluation_status = evaluation_status;
    }
}
