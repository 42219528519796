/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, } from 'react-bootstrap';
import Select from 'react-select';
import { Toaster } from 'react-hot-toast';
import ReactTooltip from 'react-tooltip';
import { VulnerabilityPolicy } from '../../../models/vulnerability-policy';
import { createVulnerabilityPolicy, editVulnerabilityPolicy } from '../../../services/policy-api';
import { showError } from '../../../utils/error';
import GlobalStateContext from '../../globalContext';
export function VulnerabilityPolicyManagementForm(props) {
    const { activePolicy, closeModal, createMode, isOpen, } = props;
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const [policy, setPolicy] = useState(activePolicy !== null && activePolicy !== void 0 ? activePolicy : new VulnerabilityPolicy());
    const [errors, setErrors] = useState();
    const onPolicyUpdate = (attribute, value) => {
        const current = Object.assign({}, policy);
        if (attribute === 'package_query_string' && typeof value === 'string' && value.trim().length === 0) {
            current[attribute] = null;
        }
        else {
            current[attribute] = value;
        }
        setPolicy(current);
    };
    const createOrUpdate = () => (createMode ? createVulnerabilityPolicy(apiUrl, csrfToken, orgName, policy) : editVulnerabilityPolicy(apiUrl, csrfToken, orgName, policy));
    const onSubmit = () => {
        createOrUpdate().then(() => {
            closeModal();
        }).catch((err) => {
            const message = JSON.parse(err.message);
            if (message.status && message.status === 422) {
                setErrors(message);
            }
            else {
                showError(message.detail, message.status);
            }
        });
    };
    const isErrorPresent = (fieldName) => (errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) && errors.fields[fieldName].length > 0;
    const getErrorText = (fieldName) => ((errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) ? errors === null || errors === void 0 ? void 0 : errors.fields[fieldName].join(', ') : '');
    if (isErrorPresent('non_field_errors')) {
        setErrors({
            code: 'invalid', detail: 'Invalid input.', fields: { name: ['This field is required.'] },
        });
    }
    const severityOptions = [
        { value: 'Critical', label: 'Critical' },
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
    ];
    const allowUnknownSeverityText = 'An "Unknown" severity level indicates a vulnerability which has no CVSS score.<br/>'
        + '"No":  Packages with such vulnerabilities are not allowed and will violate this policy.<br/>'
        + '"Yes": Packages with such vulnerabilities are allowed and will not violate this policy.';
    return (React.createElement(Modal, { id: "vulnerability-policy-modal", className: "policy-modal bootstrap-dialog type-success", show: isOpen, onHide: closeModal, backdrop: "static", contentClassName: "panel panel-success" },
        React.createElement(Modal.Header, { className: "panel-heading" },
            React.createElement(Modal.Title, { className: "bootstrap-dialog-title" }, createMode ? 'Create Vulnerability Policy' : 'Edit Vulnerability Policy')),
        React.createElement(Toaster, null),
        React.createElement(Modal.Body, { className: "panel-body" },
            React.createElement("p", null, "This policy will take action on the selected vulnerability severity and higher. If a package violates the policy it will be quarantined until it is reviewed."),
            React.createElement(Form, { className: "create-policy-form" },
                React.createElement(Form.Group, { id: "name" },
                    React.createElement(Form.Label, null, "Policy Name"),
                    React.createElement(Form.Control, { name: "name", type: "text", placeholder: "Enter policy name", isInvalid: isErrorPresent('name'), onChange: (event) => { var _a; return onPolicyUpdate('name', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: policy === null || policy === void 0 ? void 0 : policy.name, "data-testid": "name-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('name'))),
                React.createElement(Form.Group, { id: "description" },
                    React.createElement(Form.Label, null, "Description"),
                    React.createElement(Form.Control, { name: "description", type: "text", placeholder: "Enter description", isInvalid: isErrorPresent('description'), onChange: (event) => { var _a; return onPolicyUpdate('description', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: policy === null || policy === void 0 ? void 0 : policy.description, "data-testid": "description-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('description'))),
                React.createElement(Form.Group, { id: "query" },
                    React.createElement(Form.Label, null, "Package Query"),
                    React.createElement(Form.Text, { id: "queryHelp", muted: true },
                        "Use a\u00A0",
                        React.createElement("a", { href: "https://help.cloudsmith.io/docs/search-packages", target: "_blank", rel: "noreferrer" }, "search/filter query"),
                        "\u00A0for precise package policy targeting."),
                    React.createElement(Form.Control, { name: "query", type: "text", placeholder: "format:python AND downloads:>50 AND size:>2048", isInvalid: isErrorPresent('package_query_string'), onChange: (event) => { var _a; return onPolicyUpdate('package_query_string', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: (policy === null || policy === void 0 ? void 0 : policy.package_query_string) || '', "data-testid": "query-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('package_query_string'))),
                React.createElement(Form.Group, { id: "min_severity" },
                    React.createElement(Form.Label, null, "Select Vulnerability Threshold"),
                    React.createElement("p", null, "Packages that have the following severity level or higher will violate the policy"),
                    React.createElement(Select, { "aria-label": "Select Vulnerability Threshold", id: "min_severity", name: "min_severity", options: severityOptions, defaultValue: policy ? { value: policy === null || policy === void 0 ? void 0 : policy.min_severity, label: policy === null || policy === void 0 ? void 0 : policy.min_severity } : severityOptions[0], onChange: (event) => onPolicyUpdate('min_severity', event ? event.value : severityOptions[0].value), "data-testid": "min-severity-select" })),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 6, className: "py-4" },
                        React.createElement(Form.Group, null,
                            React.createElement(Form.Label, null, "Quarantine packages which violate policy"),
                            React.createElement(Form.Check, { inline: true, label: "No", name: "quarantine", type: "radio", id: "quarantine-false", onChange: () => onPolicyUpdate('on_violation_quarantine', false), defaultChecked: policy ? (policy.on_violation_quarantine === false) : true, "data-testid": "quarantine-false-radio" }),
                            React.createElement(Form.Check, { inline: true, label: "Yes", name: "quarantine", type: "radio", id: "quarantine-true", onChange: () => onPolicyUpdate('on_violation_quarantine', true), defaultChecked: policy ? (policy.on_violation_quarantine === true) : false, "data-testid": "quarantine-true-radio" }))),
                    React.createElement(Col, { md: 6, className: "py-4" },
                        React.createElement(Form.Group, { "data-tip": allowUnknownSeverityText, "data-for": "allowUnknownSeverityTooltip" },
                            React.createElement(Form.Label, null, "Allow unknown severity level"),
                            React.createElement(Form.Check, { inline: true, label: "No", name: "allow-unknown", type: "radio", id: "unknown-false", onChange: () => onPolicyUpdate('allow_unknown_severity', false), defaultChecked: policy ? (policy.allow_unknown_severity === false) : true, "data-testid": "unknown-false-radio" }),
                            React.createElement(Form.Check, { inline: true, label: "Yes", name: "allow-unknown", type: "radio", id: "unknown-true", onChange: () => onPolicyUpdate('allow_unknown_severity', true), defaultChecked: policy ? (policy.allow_unknown_severity === true) : false, "data-testid": "unknown-true-radio" })),
                        React.createElement(ReactTooltip, { id: "allowUnknownSeverityTooltip", place: "bottom", effect: "solid", multiline: true }))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: () => closeModal(), "data-testid": "cancel-btn" }, "Cancel"),
            React.createElement(Button, { variant: "success", onClick: () => onSubmit(), "data-testid": "save-btn" }, createMode ? 'Create Policy' : 'Update Policy'))));
}
