import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { BsArrowRight } from 'react-icons/bs';
export function PlaceholderCard(props) {
    const { text, icon, link, } = props;
    return (React.createElement("div", { className: "placeholder-card" },
        React.createElement(Row, null,
            React.createElement(Col, { md: 4, className: "icon" }, icon),
            React.createElement(Col, { md: 5 },
                React.createElement("div", { className: "explaination" }, text),
                link ? (React.createElement("div", { className: "doc-link" },
                    React.createElement("a", { target: "blank", href: link },
                        "Read the documentation",
                        ' ',
                        React.createElement(BsArrowRight, null)))) : React.createElement("span", null)))));
}
