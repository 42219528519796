var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { handleErrors } from '../utils/error';
// TODO: save csrf token in global state?
export function getPackage(url, csrfToken, repo, slug_perm, authType, token) {
    return __awaiter(this, void 0, void 0, function* () {
        let endpoint = `${url}v1/packages/${repo}/${slug_perm}/?authentication=${authType}`;
        if (token) {
            endpoint += `&auth_token=${token}`;
        }
        return fetch(endpoint, {
            headers: {
                'X-Csrftoken': csrfToken,
                'X-Browser-Jwt-Auth': 'true',
            },
            credentials: 'include',
        })
            .then(handleErrors)
            .then((res) => res.json());
    });
}
export function getEntitlementTokens(url, csrfToken, repo, page_size, term) {
    return __awaiter(this, void 0, void 0, function* () {
        let endpoint = `${url}v1/entitlements/${repo}/?show_tokens=true&active=true&page_size=${page_size}`;
        if (term) {
            endpoint += `&query=${term}`;
        }
        return fetch(endpoint, {
            headers: {
                'X-Csrftoken': csrfToken,
                'X-Browser-Jwt-Auth': 'true',
            },
            credentials: 'include',
        })
            .then(handleErrors)
            .then((res) => res.json());
    });
}
