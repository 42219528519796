import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsPencilFill, BsTrash, BsCheck, BsX, BsBan, } from 'react-icons/bs';
import '../../../styles/policy.scss';
import { DenyPackagePolicyManagementForm } from './form';
import { deleteDenyAllowPackagePolicy, getDenyAllowPackagePolicyList } from '../../../services/policy-api';
import { PanelTable } from '../../lib/panel-table';
import GlobalStateContext from '../../globalContext';
import { ConfirmModal } from '../../lib/confirm-modal';
export function DenyPolicyManagementList() {
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const [policies, setOrgPolicies] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState({
        create: false,
        delete: false,
    });
    const updatePolicyList = () => {
        getDenyAllowPackagePolicyList(apiUrl, csrfToken, orgName).then((data) => {
            setOrgPolicies(data);
            setLoaded(true);
        }).catch((err) => {
            const message = JSON.parse(err.message);
            toast.error(`Could not get policies: ${message.detail}`);
        });
    };
    useEffect(() => {
        updatePolicyList();
    }, []);
    const closeModal = () => {
        setSelectedPolicy(null);
        updatePolicyList();
        setShowModal({
            create: false,
            delete: false,
        });
    };
    const openModal = (policy, name) => {
        setSelectedPolicy(policy);
        setShowModal(Object.assign(Object.assign({}, showModal), { [name]: true }));
    };
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };
    const actionLinks = (policy) => (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "link", className: "edit", onClick: () => openModal(policy, 'create') },
            React.createElement(BsPencilFill, null)),
        React.createElement(Button, { variant: "link", className: "delete", onClick: () => openModal(policy, 'delete') },
            React.createElement(BsTrash, null))));
    const enabledToVisual = (enabled) => {
        if (enabled) {
            return React.createElement(BsCheck, null);
        }
        return React.createElement(BsX, null);
    };
    const policiesForTable = policies.map((policy) => ({
        id: policy.slug_perm,
        name: policy.name,
        enabled: enabledToVisual(policy.enabled),
        created: new Date(policy.created_at).toLocaleDateString(undefined, options),
        action: actionLinks(policy),
    }));
    const placeholder = {
        text: 'Create your first package deny policy using the button above. Deny access to specific packages in your organisation',
        icon: React.createElement(BsBan, { size: 120, className: "pull-right" }),
        link: null,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PanelTable, { name: "Package Deny Policies", intro: "Create a new package deny policy or edit an existing one.", data: policiesForTable, loaded: loaded, headings: [{ name: 'name', text: 'Name' }, { name: 'enabled', text: 'Enabled' }, { name: 'created', text: 'Created' }, { name: 'action', text: '', style: { width: '12rem' } }], openModal: () => openModal(null, 'create'), placeholderProps: placeholder }),
        showModal.create && (React.createElement(DenyPackagePolicyManagementForm, { isOpen: showModal.create, activePolicy: selectedPolicy, createMode: selectedPolicy === null, closeModal: () => closeModal() })),
        showModal.delete && selectedPolicy
            && (React.createElement(ConfirmModal, { completeMessage: "Policy has been deleted", confirmIcon: React.createElement(BsTrash, null), item: selectedPolicy, message: "Are you sure you want to delete this item?", modalClass: "panel-danger", show: showModal.delete, onClose: closeModal, confirmFn: deleteDenyAllowPackagePolicy }))));
}
