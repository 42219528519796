export class OrgOIDCProviderSetting {
    constructor(name = '', enabled = true, provider_url = '', claims = {}, service_accounts = [], slug = '', slug_perm = '') {
        this.name = '';
        this.enabled = true;
        this.provider_url = '';
        this.claims = {};
        this.slug = '';
        this.slug_perm = '';
        this.name = name;
        this.enabled = enabled;
        this.provider_url = provider_url;
        this.claims = claims;
        this.service_accounts = service_accounts;
        this.slug = slug;
        this.slug_perm = slug_perm;
    }
}
