import React from 'react';
import { Button } from 'react-bootstrap';
import './menu-item.scss';
export function MenuItem(props) {
    const { active, content, link, show, } = props;
    if (show) {
        return (React.createElement("div", { className: "menu-item" },
            React.createElement(Button, { className: `${active ? 'active' : ''}` },
                React.createElement("a", { href: link }, content))));
    }
    return null;
}
