import React, { useState } from 'react';
import GlobalStateContext from '../globalContext';
import { MenuItem } from './menu-item';
import './left-menu.scss';
import Account from '../../assets/icons/accounts.svg';
import Logging from '../../assets/icons/logging.svg';
import Package from '../../assets/icons/package.svg';
import Repository from '../../assets/icons/repository.svg';
import Settings from '../../assets/icons/settings.svg';
import Teams from '../../assets/icons/teams.svg';
import ExpandedAccount from '../../assets/icons/expanded-accounts-left.svg';
import ExpandedLogging from '../../assets/icons/expanded-auditlogs-left.svg';
import ExpandedPackage from '../../assets/icons/expanded-packages-left.svg';
import ExpandedRepository from '../../assets/icons/expanded-repos-left.svg';
import ExpandedSettings from '../../assets/icons/expanded-settings-left.svg';
import ExpandedTeams from '../../assets/icons/expanded-teams-left.svg';
export function LeftMenu(props) {
    const { org_name: orgName } = React.useContext(GlobalStateContext);
    const { canViewOrgMembership, canViewOrgAuditLog, canViewOrgSettings, selected, } = props;
    const menuLeftToggle = document.getElementById('menu-left-toggle');
    const menuLeftEle = document.getElementById('menu-left');
    const [open, setOpen] = useState(false);
    if (menuLeftToggle) {
        menuLeftToggle.onclick = function () {
            if (menuLeftEle) {
                menuLeftEle.className = open ? 'menu-left-closed' : 'menu-left-open';
                menuLeftToggle.className = open ? 'menu-left-toggle-closed' : 'menu-left-toggle-open';
                setOpen(!open);
            }
        };
    }
    return (React.createElement("div", null,
        React.createElement(MenuItem, { active: selected === 'repositories', content: open ? React.createElement(ExpandedRepository, null) : React.createElement(Repository, null), link: `/~${orgName}/repos`, show: true }),
        React.createElement(MenuItem, { active: selected === 'packages', content: open ? React.createElement(ExpandedPackage, null) : React.createElement(Package, null), link: `/~${orgName}/packages`, show: true }),
        React.createElement(MenuItem, { active: selected === 'people', content: open ? React.createElement(ExpandedAccount, null) : React.createElement(Account, null), link: `/orgs/${orgName}/accounts/members/`, show: canViewOrgMembership }),
        React.createElement(MenuItem, { active: selected === 'teams', content: open ? React.createElement(ExpandedTeams, null) : React.createElement(Teams, null), link: `/orgs/${orgName}/teams/`, show: canViewOrgMembership }),
        React.createElement(MenuItem, { active: selected === 'audit_log', content: open ? React.createElement(ExpandedLogging, null) : React.createElement(Logging, null), link: `/orgs/${orgName}/audit-log`, show: canViewOrgAuditLog }),
        React.createElement(MenuItem, { active: selected === 'settings', content: open ? React.createElement(ExpandedSettings, null) : React.createElement(Settings, null), link: `/orgs/${orgName}/settings/profile`, show: canViewOrgSettings })));
}
