import React from 'react';
import { Alert, Button, Col, Row, Table, } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';
import '../../styles/policy.scss';
import { PlaceholderCard } from './placeholder-card';
const defaultProps = {
    createEnabled: true,
    createDisabledReason: null,
};
export function PanelTable(props) {
    const { intro, name, data, headings, loaded, openModal, placeholderProps, createEnabled, createDisabledReason, } = props;
    return (React.createElement("div", { className: "panel panel-default panel-table" },
        React.createElement("div", { className: "panel-heading" },
            React.createElement("span", { className: "panel-title" },
                "Manage",
                ' ',
                name)),
        React.createElement(Row, { className: "panel-body" },
            React.createElement(Col, { md: 12, className: "pb-3" },
                React.createElement("p", null, intro)),
            React.createElement(Col, { md: 12 },
                React.createElement(Button, { id: "create-button", variant: "success", disabled: !createEnabled, onClick: () => openModal(null), className: "pull-right" },
                    React.createElement(BsPlusLg, null),
                    ' ',
                    "Create")),
            !createEnabled && loaded && createDisabledReason && (React.createElement(Col, { md: 12, className: "pt-3" },
                React.createElement(Alert, { className: "panel-create-disabled-reason", variant: "warning" }, createDisabledReason)))),
        loaded && data.length === 0 && placeholderProps
            ? (React.createElement(PlaceholderCard, { text: placeholderProps.text, icon: placeholderProps.icon, link: placeholderProps.link }))
            : (React.createElement("div", { className: "panel-body px-0 pt-0" },
                React.createElement(Table, { hover: true, "data-testid": "panel-table" },
                    React.createElement("thead", { className: "table-header small" },
                        React.createElement("tr", null, headings.map((heading) => React.createElement("th", { style: heading.style }, heading.text)))),
                    React.createElement("tbody", null, data.map((item) => (React.createElement("tr", { key: item.id }, headings.map((col) => React.createElement("td", null, item[col.name])))))))))));
}
PanelTable.defaultProps = defaultProps;
