import React from 'react';
import { Button, Modal, } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import '../../styles/policy.scss';
import GlobalStateContext from '../globalContext';
export function ConfirmModal(props) {
    const { completeMessage, confirmIcon, item, message, modalClass, show, confirmFn, onClose, } = props;
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const onConfirm = () => {
        confirmFn(item.slug_perm, apiUrl, csrfToken, orgName).then((success) => {
            var _a;
            if (success) {
                onClose();
                toast.success(completeMessage);
            }
            else {
                toast.error(`Unable to perform action for '${(_a = item.name) !== null && _a !== void 0 ? _a : item.slug_perm}'`);
            }
        }).catch((err) => {
            const error = JSON.parse(err.message);
            toast.error(`Unable to perform action: ${error.detail}`);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { className: "policy-modal", show: show, onHide: onClose, contentClassName: `panel panel-${modalClass}` },
            React.createElement(Modal.Header, { className: "panel-heading" },
                React.createElement(Modal.Title, null, "Please Confirm")),
            React.createElement(Modal.Body, { className: "panel-body" },
                React.createElement("p", null, message),
                React.createElement("strong", null, item && item.name)),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: onClose }, "Go Back"),
                React.createElement(Button, { variant: modalClass, onClick: () => {
                        if (item) {
                            onConfirm();
                        }
                    } }, confirmIcon))),
        React.createElement(Toaster, null)));
}
