var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PolicyEvaluationStatus } from '../models/enums';
import { handleErrors } from '../utils/error';
const commonHeaders = (csrfToken) => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Csrftoken': csrfToken,
    'X-Browser-Jwt-Auth': 'true',
});
const commonFetch = (endpoint, csrfToken, body = null, method = 'GET') => fetch(endpoint, {
    method,
    headers: commonHeaders(csrfToken),
    credentials: 'include',
    body,
})
    .then(handleErrors)
    .then((res) => {
    if (res.headers.get('Content-Type') !== 'application/json') {
        return true;
    }
    return res.json();
});
export function createLicensePolicy(url, csrfToken, org, policy) {
    const endpoint = `${url}v1/orgs/${org}/license-policy/`;
    return commonFetch(endpoint, csrfToken, JSON.stringify(policy), 'POST');
}
export function getLicensePolicyList(url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/license-policy/`;
    return commonFetch(endpoint, csrfToken);
}
export function editLicensePolicy(url, csrfToken, org, policy) {
    const endpoint = `${url}v1/orgs/${org}/license-policy/${policy.slug_perm}/`;
    return commonFetch(endpoint, csrfToken, JSON.stringify(policy), 'PUT');
}
export function getLicensePolicyWithEvalStatus(url, csrfToken, org) {
    const listEndpoint = `${url}v1/orgs/${org}/license-policy/`;
    return commonFetch(listEndpoint, csrfToken).then((policies) => Promise.all(policies.map((policy) => __awaiter(this, void 0, void 0, function* () {
        const evalEndpoint = `${url}v1/orgs/${org}/license-policy/${policy.slug_perm}/evaluation/`;
        const evals = yield commonFetch(evalEndpoint, csrfToken);
        return (Object.assign(Object.assign({}, policy), { evaluation_status: evals.length > 0 ? evals[evals.length - 1].status : PolicyEvaluationStatus.COMPLETE }));
    }))).then((data) => data));
}
export function deleteLicensePolicy(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/license-policy/${slug_perm}`;
    return commonFetch(endpoint, csrfToken, null, 'DELETE');
}
export function createLicensePolicyEvaluation(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/license-policy/${slug_perm}/evaluation/`;
    return commonFetch(endpoint, csrfToken, null, 'POST');
}
export function getLicensePolicyEvaluations(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/license-policy/${slug_perm}/evaluation/`;
    return commonFetch(endpoint, csrfToken);
}
export function createVulnerabilityPolicy(url, csrfToken, org, policy) {
    const endpoint = `${url}v1/orgs/${org}/vulnerability-policy/`;
    return commonFetch(endpoint, csrfToken, JSON.stringify(policy), 'POST');
}
export function getVulnerabilityPolicyList(url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/vulnerability-policy/`;
    return commonFetch(endpoint, csrfToken);
}
export function editVulnerabilityPolicy(url, csrfToken, org, policy) {
    const endpoint = `${url}v1/orgs/${org}/vulnerability-policy/${policy.slug_perm}/`;
    return commonFetch(endpoint, csrfToken, JSON.stringify(policy), 'PUT');
}
export function deleteVulnerabilityPolicy(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/vulnerability-policy/${slug_perm}`;
    return commonFetch(endpoint, csrfToken, null, 'DELETE');
}
export function getVulnerabilityPolicyWithEvalStatus(url, csrfToken, org) {
    const listEndpoint = `${url}v1/orgs/${org}/vulnerability-policy/`;
    return commonFetch(listEndpoint, csrfToken).then((policies) => Promise.all(policies.map((policy) => __awaiter(this, void 0, void 0, function* () {
        const evalEndpoint = `${url}v1/orgs/${org}/vulnerability-policy/${policy.slug_perm}/evaluation/`;
        const evals = yield commonFetch(evalEndpoint, csrfToken);
        return (Object.assign(Object.assign({}, policy), { evaluation_status: evals.length > 0 ? evals[evals.length - 1].status : PolicyEvaluationStatus.COMPLETE }));
    }))).then((data) => data));
}
export function createVulnerabilityPolicyEvaluation(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/vulnerability-policy/${slug_perm}/evaluation/`;
    return commonFetch(endpoint, csrfToken, null, 'POST');
}
export function getVulnerabilityPolicyEvaluations(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/vulnerability-policy/${slug_perm}/evaluation/`;
    return commonFetch(endpoint, csrfToken);
}
export function getLicenses(url, csrfToken) {
    const endpoint = `${url}v1/licenses`;
    return commonFetch(endpoint, csrfToken);
}
export function getDenyAllowPackagePolicyList(url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/deny-policy/`;
    return commonFetch(endpoint, csrfToken);
}
export function getDenyAllowPackagePolicy(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/deny-policy/${slug_perm}/`;
    return commonFetch(endpoint, csrfToken);
}
export function editDenyAllowPackagePolicy(url, csrfToken, org, policy) {
    const endpoint = `${url}v1/orgs/${org}/deny-policy/${policy.slug_perm}/`;
    return commonFetch(endpoint, csrfToken, JSON.stringify(policy), 'PUT');
}
export function deleteDenyAllowPackagePolicy(slug_perm, url, csrfToken, org) {
    const endpoint = `${url}v1/orgs/${org}/deny-policy/${slug_perm}`;
    return commonFetch(endpoint, csrfToken, null, 'DELETE');
}
export function createDenyAllowPackagePolicy(url, csrfToken, org, policy) {
    const endpoint = `${url}v1/orgs/${org}/deny-policy/`;
    return commonFetch(endpoint, csrfToken, JSON.stringify(policy), 'POST');
}
