import React, { useEffect, useState } from 'react';
import { Button, } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsFillPlayCircleFill, BsPencilFill, BsTrash } from 'react-icons/bs';
import { GiCancel } from 'react-icons/gi';
import { MdOutlinePending } from 'react-icons/md';
import { BiErrorCircle } from 'react-icons/bi';
import '../../../styles/policy.scss';
import { TbFileCertificate } from 'react-icons/tb';
import { LicensePolicyManagementForm } from './form';
import { createLicensePolicyEvaluation, deleteLicensePolicy, getLicensePolicyEvaluations, getLicensePolicyWithEvalStatus, } from '../../../services/policy-api';
import { PanelTable } from '../../lib/panel-table';
import { ConfirmModal } from '../../lib/confirm-modal';
import GlobalStateContext from '../../globalContext';
import { PolicyEvaluationStatus } from '../../../models/enums';
export function LicensePolicyManagementList() {
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const [policies, setPolicies] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState({
        create: false,
        backfill: false,
        delete: false,
    });
    const pollForEvaluation = (policy) => {
        getLicensePolicyEvaluations(policy.slug_perm, apiUrl, csrfToken, orgName).then((data) => {
            const currentEval = data[data.length - 1];
            const currentPolicies = [...policies];
            const newPolicies = currentPolicies.map((p) => {
                if (p.slug_perm === policy.slug_perm) {
                    return Object.assign(Object.assign({}, p), { evaluation_status: currentEval.status });
                }
                return p;
            });
            setPolicies(newPolicies);
        });
    };
    const evaluationStatus = new Map([
        [PolicyEvaluationStatus.PENDING, { icon: React.createElement(MdOutlinePending, { size: 18 }), class: 'warning' }],
        [PolicyEvaluationStatus.IN_PROGRESS, { icon: React.createElement(BsFillPlayCircleFill, { size: 18 }), class: 'warning' }],
        [PolicyEvaluationStatus.COMPLETE, { icon: React.createElement(BsFillPlayCircleFill, { size: 18 }), class: 'success' }],
        [PolicyEvaluationStatus.CANCELLED, { icon: React.createElement(GiCancel, { size: 18 }), class: 'danger' }],
        [PolicyEvaluationStatus.ERRORED, { icon: React.createElement(BiErrorCircle, { size: 18 }), class: 'danger' }],
    ]);
    const updatePolicyList = () => {
        getLicensePolicyWithEvalStatus(apiUrl, csrfToken, orgName).then((data) => {
            setPolicies(data);
            setLoaded(true);
        }).catch((err) => {
            const message = JSON.parse(err.message);
            toast.error(`Could not get policies: ${message.detail}`);
        });
    };
    useEffect(() => {
        updatePolicyList();
    }, []);
    useEffect(() => {
        policies.forEach((policy) => {
            if (![PolicyEvaluationStatus.COMPLETE, PolicyEvaluationStatus.CANCELLED, PolicyEvaluationStatus.ERRORED].includes(policy.evaluation_status)) {
                setTimeout(() => pollForEvaluation(policy), 5000);
            }
        });
    }, [policies]);
    const closeModal = () => {
        setSelectedPolicy(null);
        updatePolicyList();
        setShowModal({
            create: false,
            backfill: false,
            delete: false,
        });
    };
    const openModal = (policy, name) => {
        setSelectedPolicy(policy);
        setShowModal(Object.assign(Object.assign({}, showModal), { [name]: true }));
    };
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };
    const actionLink = (policy) => {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "link", className: (_a = evaluationStatus.get(policy.evaluation_status)) === null || _a === void 0 ? void 0 : _a.class, onClick: () => openModal(policy, 'backfill'), disabled: [PolicyEvaluationStatus.PENDING, PolicyEvaluationStatus.IN_PROGRESS].includes(policy.evaluation_status) }, (_b = evaluationStatus.get(policy.evaluation_status)) === null || _b === void 0 ? void 0 : _b.icon),
            React.createElement(Button, { variant: "link", className: "edit", onClick: () => openModal(policy, 'create') },
                React.createElement(BsPencilFill, null)),
            React.createElement(Button, { variant: "link", className: "delete", onClick: () => openModal(policy, 'delete') },
                React.createElement(BsTrash, null))));
    };
    const policiesForTable = policies.map((policy) => ({
        id: policy.slug_perm,
        name: policy.name,
        created: new Date(policy.created_at).toLocaleDateString(undefined, options),
        quarantine: policy.on_violation_quarantine ? 'Yes' : 'No',
        action: actionLink(policy),
    }));
    const placeholder = {
        text: 'Create your first license policy using the button above. Flag or quarantine packages using prohibited licenses, giving you better control over compliance.',
        icon: React.createElement(TbFileCertificate, { size: 120, className: "pull-right" }),
        link: 'https://help.cloudsmith.io/docs/license-policy',
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PanelTable, { name: "License Policies", intro: "Create a new license policy or edit an existing one. Policies are evaluated on package synchronization or license change. New policies will apply to newly uploaded packages and existing packages when resynchronized.", data: policiesForTable, loaded: loaded, headings: [{ name: 'name', text: 'Name' }, { name: 'created', text: 'Created' }, { name: 'quarantine', text: 'Quarantine', style: { width: '14rem' } }, { name: 'action', text: '', style: { width: '16rem' } }], openModal: () => openModal(null, 'create'), placeholderProps: placeholder }),
        showModal.create && (React.createElement(LicensePolicyManagementForm, { isOpen: showModal.create, activePolicy: selectedPolicy, createMode: selectedPolicy === null, closeModal: () => closeModal() })),
        showModal.delete && selectedPolicy
            && (React.createElement(ConfirmModal, { completeMessage: "Policy has been deleted", confirmIcon: React.createElement(BsTrash, null), item: selectedPolicy, message: "Are you sure you want to delete this item?", modalClass: "danger", show: showModal.delete, onClose: closeModal, confirmFn: deleteLicensePolicy })),
        showModal.backfill && selectedPolicy
            && (React.createElement(ConfirmModal, { completeMessage: "Backfill has been scheduled", confirmIcon: React.createElement(BsFillPlayCircleFill, null), item: selectedPolicy, message: "Are you sure you want to backfill this policy?", modalClass: "warning", show: showModal.backfill, onClose: closeModal, confirmFn: createLicensePolicyEvaluation }))));
}
