import React, { useState } from 'react';
import { Button, Form, Modal, } from 'react-bootstrap';
import Select from 'react-select';
import * as Sentry from '@sentry/browser';
import { Toaster } from 'react-hot-toast';
import { OrgOIDCProviderSetting } from '../../../models/oidc-provider-setting';
import { showError } from '../../../utils/error';
import { createProviderSetting, editProviderSetting } from '../../../services/openid-connect';
import GlobalStateContext from '../../globalContext';
import '../../../styles/oidc.scss';
export function ProviderSettingsManagementForm(props) {
    const { activeSettings, closeModal, createMode, isOpen, serviceAccounts, } = props;
    const defaultErrors = { code: '', detail: '', fields: {} };
    const { org_name: orgName, api_url: apiUrl, csrf_token: csrfToken } = React.useContext(GlobalStateContext);
    const [setting, setSetting] = useState(activeSettings !== null && activeSettings !== void 0 ? activeSettings : new OrgOIDCProviderSetting());
    const [errors, setErrors] = useState(defaultErrors);
    const [claimParseErrors, setClaimParseErrors] = useState('');
    const isErrorPresent = (fieldName) => (errors === null || errors === void 0 ? void 0 : errors.fields[fieldName]) && errors.fields[fieldName].length > 0;
    const getErrorText = (fieldName) => {
        if (errors.fields[fieldName] !== undefined && errors.fields[fieldName] !== null) {
            return errors.fields[fieldName].join(', ');
        }
        return '';
    };
    const setErrorText = (fieldName, value) => {
        const newErrors = errors;
        newErrors.fields[fieldName] = value;
        setErrors(newErrors);
    };
    const onSettingsUpdate = (attribute, value) => {
        const current = Object.assign({}, setting);
        current[attribute] = value;
        setErrorText(attribute, []);
        setSetting(current);
    };
    const createOrUpdate = () => (createMode ? createProviderSetting(apiUrl, csrfToken, orgName, setting) : editProviderSetting(apiUrl, csrfToken, orgName, setting));
    const onSubmit = () => {
        createOrUpdate().then(() => {
            closeModal();
        }).catch((err) => {
            const message = JSON.parse(err.message);
            if (message.status && message.status === 422) {
                setErrors(message);
            }
            else {
                showError(message.detail, message.status);
            }
        });
    };
    const makeServiceAccountOption = (account) => ({ value: account.slug, label: account.name });
    const serviceAccountOptions = (accounts) => (accounts.flatMap((account) => makeServiceAccountOption(account)));
    const getServiceAccountValue = () => {
        const settingAccountSlugs = setting === null || setting === void 0 ? void 0 : setting.service_accounts;
        const accounts = serviceAccounts.filter((account) => settingAccountSlugs.includes(account.slug));
        if (accounts !== undefined && accounts !== null) {
            return accounts.map((account) => ({ label: account.name, value: account.slug }));
        }
        return undefined;
    };
    const getClaimsErrors = () => {
        const apiErrors = getErrorText('claims');
        const parseErrors = claimParseErrors;
        return parseErrors + apiErrors;
    };
    const areAnyAPIErrorsPresent = () => {
        const fields = Object.keys(setting);
        const fieldsWithErrors = fields.filter((fieldName) => isErrorPresent(fieldName));
        return fieldsWithErrors.length > 0;
    };
    const areAnyClaimsParsingErrorsPresent = () => claimParseErrors.length > 0;
    const formHasErrors = () => areAnyAPIErrorsPresent() || areAnyClaimsParsingErrorsPresent();
    return (React.createElement(Modal, { id: "provider-settings-modal", className: "oidc-modal bootstrap-dialog type-success" // TODO: remove oidc-modal once styling applied to default modal
        , show: isOpen, onHide: closeModal, backdrop: "static", contentClassName: "panel panel-success" },
        React.createElement(Modal.Header, { className: "panel-heading" },
            React.createElement(Modal.Title, { className: "bootstrap-dialog-title" }, createMode ? 'Create Provider Settings' : 'Edit Provider Settings')),
        React.createElement(Toaster, null),
        React.createElement(Modal.Body, { className: "panel-body" },
            React.createElement("p", null, "Once configured, OIDC requests with a token made by this provider will be able to authenticate as any of the configured service accounts, provided that the token's claims match those configured here."),
            React.createElement(Form, { className: "create-provider-settings-form", role: "form" },
                React.createElement(Form.Group, { id: "name" },
                    React.createElement(Form.Label, null, "Provider Name"),
                    React.createElement(Form.Control, { name: "name", type: "text", placeholder: "Enter provider name", isInvalid: isErrorPresent('name'), onChange: (event) => { var _a; return onSettingsUpdate('name', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: setting === null || setting === void 0 ? void 0 : setting.name, "data-testid": "name-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('name'))),
                React.createElement(Form.Group, { id: "provider-url" },
                    React.createElement(Form.Label, null, "Provider URL"),
                    React.createElement(Form.Control, { name: "provider_url", type: "text", isInvalid: isErrorPresent('provider_url'), onChange: (event) => { var _a; return onSettingsUpdate('provider_url', (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: setting === null || setting === void 0 ? void 0 : setting.provider_url, "data-testid": "provider-url-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('provider_url'))),
                React.createElement(Form.Group, { id: "claims" },
                    React.createElement(Form.Label, null, "Required OpenID Token Claims"),
                    React.createElement(Form.Control, { name: "claims", as: "textarea", isInvalid: isErrorPresent('claims'), onChange: (event) => {
                            var _a;
                            try {
                                setClaimParseErrors('');
                                const claims = JSON.parse((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value);
                                // eslint-disable-next-line eqeqeq
                                if (typeof claims === 'object') {
                                    onSettingsUpdate('claims', claims);
                                }
                                else {
                                    setClaimParseErrors('Claims must be a JSON object');
                                }
                            }
                            catch (error) {
                                if (error instanceof SyntaxError) {
                                    setClaimParseErrors('Claims must be valid JSON');
                                }
                                else {
                                    setClaimParseErrors('An error has occurred parsing claims, our engineers have been notified');
                                    Sentry.captureException(error);
                                }
                            }
                        }, defaultValue: JSON.stringify(setting === null || setting === void 0 ? void 0 : setting.claims, null, 4), "data-testid": "claims-input" }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getClaimsErrors()),
                    React.createElement(Form.Text, { muted: true },
                        "We",
                        ' ',
                        React.createElement("strong", null, "strongly recommend"),
                        ' ',
                        "that you include at least one claim to validate to ensure that only requests under your organization's control are able to authenticate as one of the configured service accounts.")),
                React.createElement(Form.Group, null,
                    React.createElement(Form.Label, { htmlFor: "serviceAccounts" }, "Service Accounts"),
                    React.createElement(Select, { inputId: "serviceAccounts", options: serviceAccountOptions(serviceAccounts), isMulti: true, onChange: (value) => onSettingsUpdate('service_accounts', value.map((account) => (account.value))), value: getServiceAccountValue() }),
                    React.createElement(Form.Control.Feedback, { type: "invalid", className: "text-danger" }, getErrorText('service_accounts'))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Button, { variant: "secondary", onClick: () => closeModal(), "data-testid": "cancel-btn" }, "Cancel"),
            React.createElement(Button, { variant: "success", onClick: () => onSubmit(), disabled: formHasErrors(), "data-testid": "save-btn" }, createMode ? 'Create Settings' : 'Update Settings'))));
}
